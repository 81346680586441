<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron presupuestos de clientes"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :toolbar-options="toolbarOptions"
      :page-loaded="pageLoaded"
      pk-name="idpresupuestocli"
      @click-item="clickListItem"
      @click-toolbar-option="clickToolbarOption"
      @open-filter="openFilter"
    >
      <template
        #listItem="slotProps"
      >
        <presupuestocli-list-item
          :item="slotProps.item"
        />
      </template>
    </b10-list>
    <presupuestocli-list-legend
      :showing.sync="showingDialogs.legends"
    />
    <b10-map-markers
      :markers="mapMarkers"
      title="Mapa presupuestos"
      :showing.sync="showingDialogs.map"
      icon-fieldname="icon"
      @bounds-changed="mapBoundsChanged"
      @click-marker="clickMapMarker"
    />
    <b10-map-marker-info
      :title="mapMarkerInfo.title"
      :subtitle="mapMarkerInfo.subtitle"
      :lat="mapMarkerInfo.lat"
      :lng="mapMarkerInfo.lng"
      :showing.sync="showingDialogs.mapMarkerInfo"
    >
      <template slot="cardExtraButtons">
        <v-btn
          color="info"
          dark
          @click="clickVerPresupuesto(mapMarkerInfo.idpresupuestocli)"
        >
          <v-icon left>
            {{ $vuetify.icons.values.presupuestos }}
          </v-icon> Ver presupuesto
        </v-btn>
      </template>
    </b10-map-marker-info>
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './PresupuestocliListData'
import PresupuestocliListItem from './components/PresupuestocliListItem'
import PresupuestocliListLegend from './components/PresupuestocliListLegend'
import { get } from 'vuex-pathify'
import _ from '@/utils/lodash'
import { markerIcons } from '@/utils/maps'

const pageStoreName = 'pagesPresupuestocliList'

export default {
  components: {
    PresupuestocliListItem, PresupuestocliListLegend,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  props: {
    clasificacionEstados: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      pageStoreName,
      toolbarOptions: {
        map: {
          title: 'Ver en el mapa',
          visible: true,
          icon: 'map',
        },
        legend: {
          title: 'Colores y leyendas',
          visible: true,
          icon: 'info',
        },
      },
      showingDialogs: {
        legends: false,
        mapMarkerInfo: false,
        map: false,
      },
      mapMarkerInfo: {
        title: '',
        subtitle: '',
        lat: null,
        lng: null,
      },
      mapMarkers: [],
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Presupuestos'
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectPresupuestocli(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.clasificacionEstados,
          this.$route.params.idcliente,
          this.$route.params.idcliente_potencial
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        const [dataset] = await Data.selectPresupuestocliRows(
          this,
          dirty.modified,
          this.clasificacionEstados
        )
        await this.reloadItemsBase(dataset, dirty, 'idpresupuestocli')
      } finally {
        this.loadingData = false
      }
    },
    async openFilter () {
      // en evento openFilter y no en propiedad initFilter para no cargar
      // datos de los select hasta que no se abre el dialog del filtro
      if (
        this.filter.tactuacion.items.length === 0 &&
        this.filter.tsistema.items.length === 0
      ) {
        const resp = await Data.selectFilterLookups(this)
        await this.setStoreProperty('filter@tactuacion.items', resp.data.selectTactuacion.result.dataset)
        await this.setStoreProperty('filter@tsistema.items', resp.data.selectTsistema.result.dataset)
      }
    },
    goToView (idpresupuestocli) {
      this.rememberState = true
      this.rememberScroll = true
      this.$appRouter.push({
        name: 'presupuestos__presupuestocli-view',
        params: {
          idpresupuestocli: idpresupuestocli,
        },
      })
    },
    clickListItem (item) {
      this.goToView(item.data.idpresupuestocli)
    },
    clickToolbarOption (option) {
      if (option === this.toolbarOptions.legend) {
        this.showingDialogs.legends = true
      } else if (option === this.toolbarOptions.map) {
        this.showingDialogs.map = true
      }
    },
    clickMapMarker (markerData) {
      this.mapMarkerInfo.title = this.$online.presupuestocli.title(markerData)
      this.mapMarkerInfo.subtitle = this.$online.presupuestocli.subtitle(markerData)
      this.mapMarkerInfo.lat = markerData.latitud
      this.mapMarkerInfo.lng = markerData.longitud
      this.mapMarkerInfo.idpresupuestocli = markerData.idpresupuestocli
      this.showingDialogs.mapMarkerInfo = !this.showingDialogs.mapMarkerInfo
    },
    async mapBoundsChanged (bounds) {
      let [markers] = await Data.selectPresupuestocli(
        this,
        this.filter,
        this.search,
        this.sorter,
        0,
        this.clasificacionEstados,
        this.$route.params.idcliente,
        this.$route.params.idcliente_potencial,
        bounds,
        0
      )
      markers = _.map(markers, (marker) => {
        marker.icon = markerIcons.presupuestocli
        return marker
      })
      this.mapMarkers = markers
    },
    clickVerPresupuesto(idpresupuestocli) {
      this.showingDialogs.map = !this.showingDialogs.map
      this.showingDialogs.mapMarkerInfo = !this.showingDialogs.mapMarkerInfo
      this.goToView(idpresupuestocli)
    },
  },
}
</script>
